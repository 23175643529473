import _reactRouterDomProductionMin from "./umd/react-router-dom.production.min.js";
/* eslint-env node */
export { _reactRouterDomProductionMin as default };
export const AbortedDeferredError = _reactRouterDomProductionMin.AbortedDeferredError,
  Await = _reactRouterDomProductionMin.Await,
  MemoryRouter = _reactRouterDomProductionMin.MemoryRouter,
  Navigate = _reactRouterDomProductionMin.Navigate,
  NavigationType = _reactRouterDomProductionMin.NavigationType,
  Outlet = _reactRouterDomProductionMin.Outlet,
  Route = _reactRouterDomProductionMin.Route,
  Router = _reactRouterDomProductionMin.Router,
  Routes = _reactRouterDomProductionMin.Routes,
  UNSAFE_DataRouterContext = _reactRouterDomProductionMin.UNSAFE_DataRouterContext,
  UNSAFE_DataRouterStateContext = _reactRouterDomProductionMin.UNSAFE_DataRouterStateContext,
  UNSAFE_LocationContext = _reactRouterDomProductionMin.UNSAFE_LocationContext,
  UNSAFE_NavigationContext = _reactRouterDomProductionMin.UNSAFE_NavigationContext,
  UNSAFE_RouteContext = _reactRouterDomProductionMin.UNSAFE_RouteContext,
  UNSAFE_useRouteId = _reactRouterDomProductionMin.UNSAFE_useRouteId,
  createMemoryRouter = _reactRouterDomProductionMin.createMemoryRouter,
  createPath = _reactRouterDomProductionMin.createPath,
  createRoutesFromChildren = _reactRouterDomProductionMin.createRoutesFromChildren,
  createRoutesFromElements = _reactRouterDomProductionMin.createRoutesFromElements,
  defer = _reactRouterDomProductionMin.defer,
  generatePath = _reactRouterDomProductionMin.generatePath,
  isRouteErrorResponse = _reactRouterDomProductionMin.isRouteErrorResponse,
  json = _reactRouterDomProductionMin.json,
  matchPath = _reactRouterDomProductionMin.matchPath,
  matchRoutes = _reactRouterDomProductionMin.matchRoutes,
  parsePath = _reactRouterDomProductionMin.parsePath,
  redirect = _reactRouterDomProductionMin.redirect,
  redirectDocument = _reactRouterDomProductionMin.redirectDocument,
  renderMatches = _reactRouterDomProductionMin.renderMatches,
  replace = _reactRouterDomProductionMin.replace,
  resolvePath = _reactRouterDomProductionMin.resolvePath,
  useActionData = _reactRouterDomProductionMin.useActionData,
  useAsyncError = _reactRouterDomProductionMin.useAsyncError,
  useAsyncValue = _reactRouterDomProductionMin.useAsyncValue,
  useBlocker = _reactRouterDomProductionMin.useBlocker,
  useHref = _reactRouterDomProductionMin.useHref,
  useInRouterContext = _reactRouterDomProductionMin.useInRouterContext,
  useLoaderData = _reactRouterDomProductionMin.useLoaderData,
  useLocation = _reactRouterDomProductionMin.useLocation,
  useMatch = _reactRouterDomProductionMin.useMatch,
  useMatches = _reactRouterDomProductionMin.useMatches,
  useNavigate = _reactRouterDomProductionMin.useNavigate,
  useNavigation = _reactRouterDomProductionMin.useNavigation,
  useNavigationType = _reactRouterDomProductionMin.useNavigationType,
  useOutlet = _reactRouterDomProductionMin.useOutlet,
  useOutletContext = _reactRouterDomProductionMin.useOutletContext,
  useParams = _reactRouterDomProductionMin.useParams,
  useResolvedPath = _reactRouterDomProductionMin.useResolvedPath,
  useRevalidator = _reactRouterDomProductionMin.useRevalidator,
  useRouteError = _reactRouterDomProductionMin.useRouteError,
  useRouteLoaderData = _reactRouterDomProductionMin.useRouteLoaderData,
  useRoutes = _reactRouterDomProductionMin.useRoutes,
  UNSAFE_ErrorResponseImpl = _reactRouterDomProductionMin.UNSAFE_ErrorResponseImpl,
  BrowserRouter = _reactRouterDomProductionMin.BrowserRouter,
  Form = _reactRouterDomProductionMin.Form,
  HashRouter = _reactRouterDomProductionMin.HashRouter,
  Link = _reactRouterDomProductionMin.Link,
  NavLink = _reactRouterDomProductionMin.NavLink,
  RouterProvider = _reactRouterDomProductionMin.RouterProvider,
  ScrollRestoration = _reactRouterDomProductionMin.ScrollRestoration,
  UNSAFE_FetchersContext = _reactRouterDomProductionMin.UNSAFE_FetchersContext,
  UNSAFE_ViewTransitionContext = _reactRouterDomProductionMin.UNSAFE_ViewTransitionContext,
  UNSAFE_useScrollRestoration = _reactRouterDomProductionMin.UNSAFE_useScrollRestoration,
  createBrowserRouter = _reactRouterDomProductionMin.createBrowserRouter,
  createHashRouter = _reactRouterDomProductionMin.createHashRouter,
  createSearchParams = _reactRouterDomProductionMin.createSearchParams,
  unstable_HistoryRouter = _reactRouterDomProductionMin.unstable_HistoryRouter,
  unstable_usePrompt = _reactRouterDomProductionMin.unstable_usePrompt,
  unstable_useViewTransitionState = _reactRouterDomProductionMin.unstable_useViewTransitionState,
  useBeforeUnload = _reactRouterDomProductionMin.useBeforeUnload,
  useFetcher = _reactRouterDomProductionMin.useFetcher,
  useFetchers = _reactRouterDomProductionMin.useFetchers,
  useFormAction = _reactRouterDomProductionMin.useFormAction,
  useLinkClickHandler = _reactRouterDomProductionMin.useLinkClickHandler,
  useSearchParams = _reactRouterDomProductionMin.useSearchParams,
  useSubmit = _reactRouterDomProductionMin.useSubmit,
  __esModule = _reactRouterDomProductionMin.__esModule;